.single-directory {
	&__pager {
		.pager {
			li {
				&.pager__prev, &.pager__next {
					span, a {
						padding: em(1.55, 1.4) em(2.25, 1.4);
					}
				}
			}
		}
	}
}