.home-main-slider {
  .info-block {
    width: 306px;
    height: 306px;
    bottom: -230px;
    left: calc(50% - 153px);
    > p {
      font-size: 1.3em;
    }
    h2 {
      a {
        font-size: em(2.2, 1.5);
      }
    }
    &:before,
    &:after {
      top: -170px;
    }
  }
  .prevNextCarrousel1 {
    li {
      &.prevCarrousel1,
      &.nextCarrousel1 {
        bottom: 10px;
      }
      &.prevCarrousel1 {
        left: calc(50% - 161.5px);
      }
      &.nextCarrousel1 {
        right: calc(50% - 164.5px);
      }
    }
  }
  .pagerCarrousel1,
  button.buttonStop.modeplay {
    display: none;
  }
}