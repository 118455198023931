.single-directory {
	&__pager {
		background: $color-3--1;
		.section-main__wrapper {
			padding-top: 0;
			padding-bottom: 0;
		}
		.pager {
			ul {
				margin-top: 0;
				margin-bottom: 6em;
			}
			li {
				a,
				span {
					padding: em(1.55, 1.4) em(2.25, 1.4);
				}
				&.pager__prev, &.pager__next {
					span, a {
						text-indent: -9999px;
						white-space: nowrap;
						position: relative;
						&:before, &:after {
							text-indent: 0;
							line-height: 0;
							display: block;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							margin: 0;
							background: none;
						}
					}
				}
				&.pager__prev {
					a, span {
						&:before {
							font-size: 1.5em;
							content: "\e012";
						}
					}
				}
				&.pager__next {
					a, span {
						&:after {
							font-size: 1.5em;
							content: "\e013";
						}
					}
					&:hover,
					&:focus {
						a, span {
							&:after {
								color: $color-white;
							}
						}
					}
				}
				&.pager-single__back-to-list {
					width: calc(100% - 110px);
					a {
						width: 100%;
						background: none;
						&:before {
							content: none;
						}
						&:hover {
							background: $color-2--2;
						}
					}
				}
			}
		}
	}
}