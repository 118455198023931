// @name Header page
.header {
    height: 7rem;
    &__right {
        top: 0.9rem;
        right: 5.8rem;
        > div {
            padding: 0 0.1rem;
            a {
                letter-spacing: -0.05rem;
            }
        }
        &-recherche {
            p {
                font-size: 0.9rem;
                letter-spacing: -0.05rem;
            }
        }
    }
}

// @name Top of content
.top-of-content {
    display: none;
}

// @name Main section wrappers
.section-main__wrapper {
    width: 100%;
    padding-top: 3em;
    padding-bottom: 3em;
}

.section-main__wrapper-2 {
    display: flex;
    flex-direction: column;
    > * {
        display: block;
        vertical-align: inherit;
    }
}

// @name Main section aside
.section-main__aside {
    padding: 0;
}

// @name Footer
.footer {
    .footer__wrapper-2 {
        margin: 0;
        display: block;
        > * {
            flex: inherit;
            margin: 0 0 2em 0;
        }
    }
}
