.home-kh {
    &-left,
    &-right {
        float: left;
    }
    &-left {
        width: 72%;
        position: relative;
        overflow: hidden;
        .kh-left-info {
            width: 395px;
            height: 395px;
            padding: 4.2em 9.7em;
            position: absolute;
            bottom: -197px;
            left: -60px;
            text-align: center;
            font-family: "Roboto Condensed", sans-serif;
            background: #fff;
            border-radius: 50%;
            a.plus {
                width: 33px;
                height: 33px;
                display: block;
                margin: 0 auto 15px;
                padding-top: 6px;
                border: 1px solid $color-2--1;
                @include default-icons-before('\e08a', $margin: 0, $size: 1.8em, $color: $color-2--1, $va: middle, $font: 'icons-default');
            }
            div > a {
                display: block;
                position: relative;
                font-size: 1.3em;
                font-weight: $bold;
                color: $color-2--2;
                text-transform: uppercase;
                &:after {
                    position: absolute;
                    bottom: -7px;
                    left: 50%;
                    transform: translateX(-50%);
                    content: '';
                    width: 33px;
                    height: 1px;
                    background: #ed6d07;
                }
            }
            p {
                margin-top: 15px;
                a {
                    font-size: 18px;
                    font-weight: $light;
                    color: $color-black;
                }
            }
        }
    }
    &-right {
        width: 28%;
        .kh-right-item {
            position: relative;
            text-align: center;
            .kh-right-info {
                width: 99%;
                padding: 15px 10px 10px;
                position: absolute;
                left: 0.5%;
                bottom: 0;
                font-family: $typo-2;
                @include background-opacity($color-1--2, $opacity: 0.9);
                > a {
                    font-size: 1.3em;
                    color: $color-2--2;
                    text-transform: uppercase;
                    font-weight: bold;
                }
                p {
                    a {
                        font-size: 1.4em;
                        color: $color-black;
                        font-weight: $light;
                    }
                }
            }
            &:nth-child(1) {
                margin-bottom: 2px;
            }
        }
        text-align: right;
    }
    .button-2 {
        width: 100%;
        padding-top: 1.17857em;
        padding-bottom: 1.17857em;
        margin-top: 0.71429em;
        text-align: center;
        &:before {
            content: none;
        }
    }
}
