// @name List infos
.list-infos {
    font-family: $typo-2;
}

.list-infos__wrapper {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    > * {
        flex: 1 1 0;
    }
    .list-infos__listitems:first-child {
        flex: 1.9 1 0;
    }
    .list-infos__listitems + .list-infos__listitems {
        //border-left: 1px solid $color-3--3;
        padding-left: 5%;
        margin-left: 5%;
    }
    .button-1 {
        background: $color-1--2;
        color: $color-2--2;
        padding: em(1.75, 1.4) em(1.5, 1.4);
        &:hover {
            background: $color-1--4;
        }
    }
    .contact {
        .button-2 {
            min-width: 20rem;
        }
    }
}

.list-infos__item {
    $list-infos__item__font-size: 1.4;
    margin: .5em 0;
    color: $color-3--3;
    font-size: #{$list-infos__item__font-size}em;
    //@include default-icons-absolute-before('\e005', 35px, $color-2--2, 2px, inherit, inherit, 0, 'icons-project');
    @include default-icons-absolute-before('\e053', em(1.4, $list-infos__item__font-size), $color-1--2, em(0.2, 1.4), inherit, inherit, 0);
    padding-left: em(2, $list-infos__item__font-size);
    span.infos-subtext {
        display: block;
        font-style: italic;
        font-size: em(1.6, 1.8);
    }
    a {
        color: $color-3--3;
    }
    &.list-infos__phone, &.list-infos__fax {
        //white-space: nowrap;
    }
    &.list-infos__address {
        &:before {
            font-family: "icons-project";
            content: "\e00c";
        }
    }
    &.list-infos__website {
        &:before {
            content: "\e089";
        }
    }
    &.list-infos__phone {
        &:before {
            font-family: "icons-project";
            content: "\e00f";
        }
    }
    &.list-infos__fax {
        &:before {
            content: "\e09f";
        }
    }
    &.list-infos__email {
        &:before {
            content: "\e028";
        }
    }
    &.list-infos__infos {
        &:before {
            content: "\e083";
        }
    }
    &.list-infos__hours {
        &:before {
            content: "\e05b";
        }
    }
    &.list-infos__attachment {
        &:before {
            font-family: "icons-project";
            content: "\e009";
        }
    }
    &.list-infos__access {
        &:before {
            font-family: "icons-project";
            content: "\e008";
        }
    }
    &.list-infos__zone {
        &:before {
            font-family: "icons-project";
            content: "\e00d";
        }
    }
    &.list-infos__station {
        &:before {
            font-family: "icons-project";
            content: "\e00e";
        }
    }
}
