// @name Title 1
// @description Styling for title 1
$title--1__font-size: 4.4;
.title-1 {
    font-family: $typo-2;
    font-size: #{$title--1__font-size}em;
    line-height: 1em;
    font-weight: $light;
    text-transform: uppercase;
    color: $color-2--2;
    margin: em(3, $title--1__font-size) 0 em(2, $title--1__font-size);
}

// @name Title 2
// @description Styling for title 2
$title--2__font-size: 1.8;
.title-2 {
    font-family: $typo-3;
    font-size: #{$title--2__font-size}em;
    font-weight: $normal;
    text-transform: uppercase;
    margin: em(2.5, $title--2__font-size) 0 em(1.5, $title--2__font-size);
}

// @name Title 3
// @description Styling for title 3
$title--3__font-size: 2.5;
.title-3 {
    position: relative;
    font-family: $typo-2;
    font-size: #{$title--3__font-size}em;
    line-height: 1em;
    font-weight: $bold;
    text-transform: uppercase;
    color: $color-2--2;
    margin: em(2.5, $title--3__font-size) 0 em(2, $title--3__font-size);
    overflow: hidden;
}

.section-main__aside {
    .title-3 {
        position: relative;
        &:after {
            height: 17px;
            width: 100%;
            content: '';
            position: absolute;
            top: 3px;
            background: url(../Images/bg/aside-title-bg.png) repeat-x;
        }
    }
}
.section-main__wrapper,
.section-main__wrapper-2 {
    .title-3 {
        margin-top: em(7, 2.5);
        position: relative;
        overflow: hidden;
        span {
            padding-right: 1rem;
            position: relative;
            background-color: $color-white;
            white-space: pre-wrap;
        }
        &:before {
            height: 30px;
            width: 100%;
            content: '';
            position: absolute;
            top: 2.5px;
            background: url(../Images/bg/home-title-bg.png) repeat-x;
        }
    }
}
