// @name Background-in-wrapper
// @description Displayed in grey background on the sides.
.background-in-wrapper {
    margin: 0 -1em;
    padding: 0 1em;
    &:before, &:after {
        width: 0;
    }
}

// @name Column bloc
// @description Generic bloc in .section-main__aside
.column-bloc {
    width: 100%;
    margin: 0 auto 9em;
    padding-left: 0;
    &:last-child {
        margin-bottom: 6em;
    }
}
