.menu-main-1__listitems-0 {
    > li {
        > .menu-main-1__header {
            .menu-main-1__action {
                top: 0.9rem;
                left: -5.5rem;
            }
        }
    }
}
