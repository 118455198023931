.menu-links {
    display: none;
    margin-bottom: 5rem;
    margin-left: 2.5rem;
    justify-content: center;
    a {
        display: block;
        margin: 1rem 0;
        font-size: 1.4rem;
        text-align: left;
        text-transform: uppercase;
        color: $color-2--3;
        img {
            width: 3.5rem;
            height: auto;
            margin-right: 0.5rem;
            vertical-align: middle;
        }
    }
}
