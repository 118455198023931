.menu-main-1__sublevel {
    display: none;
    &[aria-hidden="false"] {
        display: block;
    }
}

//
.menu-main-1__item--no-action-in-desktop {
    .menu-main-1__sublevel {
        display: block;
    }
}

/* Styles */

// All level
.menu-main-1__wrapper {
    position: relative;
}

.menu-main-1 {
    @extend .wrapper-main;
}

.menu-main-1__item {
    > .menu-main-1__header {
        a {
            display: block;
        }
    }
    > .menu-main-1__sublevel {

    }
}

.menu-main-1__closing-button {
    text-align: left;
    text-indent: -9999px;
    white-space: nowrap;
    position: absolute;
    top: .5rem;
    right: .5rem;
    width: 30px;
    height: 30px;
    background: transparent;
    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%);
        content: "X";
        font-size: 1.3em;
        font-weight: $bold;
        text-indent: 0;
        line-height: 0;
        display: block;
        margin: 0;
        color: #03498b;
    }
}

// Level 0 (displayed in tablet and smartphone)
.menu-main-1__listitems-0 {
    text-align: center;
    > li {
        > .menu-main-1__header {
            display: none;
        }
        > .menu-main-1__sublevel {
            display: block;
            > .menu-main-1__closing-button {
                display: none;
            }
        }
    }
}

// Level 1
.menu-main-1__listitems-1 {
    > li {
        display: inline-block;
        vertical-align: middle;
        > .menu-main-1__header {
            > a {
                position: relative;
                font-size: 1.6em;
                color: $color-2--2;
                font-weight: $bold;
                text-transform: uppercase;
                padding: em(5.6, 1.6) em(2, 2) em(2, 1.6);
                &:hover {
                    color: $color-white;
                    text-decoration: none;
                }
                &:after {
                    width: 1px;
                    height: 74px;
                    position: absolute;
                    top: 0;
                    right: -1.7px;
                    content: '';
                    background: $color-4--3;
                }
            }
        }
        > .menu-main-1__sublevel {
            display: block;
            position: absolute;
            z-index: 10;
            top: 100%;
            left: 0;
            right: 0;
            width: 100%;
            max-height: 0;
            visibility: hidden;
            overflow: hidden;
            transition: visibility ease .2s, max-height ease .2s;
            .menu-main-1__listitems-1__wrapper {
                text-align: left;
                padding: 6.5em 0 14.5em;
                transform: translateY(-110%);
                transition: transform ease-in .2s;
                height: 100%;
                background: $color-2--2;
            }
            // level 1 Displayed
            &[aria-hidden="false"] {
                visibility: visible;
                max-height: 1000px;
                .menu-main-1__listitems-1__wrapper {
                    transform: translateY(0);
                    transition: transform ease-out .4s .2s;
                }
            }
            a, button, p {
                color: $color-white;
                &:focus {
                    outline-color: $color-white;
                }
            }
            button {
                width: 20px;
                height: 20px;
                color: $color-1--4;
                opacity: 0.9;
            }
        }
        &:hover {
            background: $color-2--2;
        }
        &:last-child {
            > .menu-main-1__header {
                > a:after {
                    content: none;
                }
            }
        }
        &.menu-main-1__item--active {
            background: $color-2--2;
            .menu-main-1__header {
                a {
                    color: $color-white;
                    text-decoration: none;
                }
            }
        }
    }
}

// Level 2
.menu-main-1__listitems-2 {
    width: 39rem;
    position: relative;
    height: auto;
    transition: height ease .3s;
    border-right: 5px solid $color-2--5;
    > li {
        display: block;
        padding: 1em 1.7em;
        border-bottom: 1px solid $color-2--5;
        // Level 2 and 3
        .menu-main-1__header {
            position: relative;
            .menu-main-1__action {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                text-align: left;
                text-indent: -9999px;
                white-space: nowrap;
                width: 25px;
                height: 25px;
                background: $color-1--4;
                overflow: hidden;
                flex-basis: 3rem;
                border-radius: 50%;
                &:before {
                    position: absolute;
                    top: calc(50% - 6.5px);
                    left: calc(50% - 6px);
                    font-family: "icons-default";
                    content: "\e01b";
                    font-size: 1em;
                    color: $color-2--2;
                    font-weight: $bold;
                    text-indent: 0;
                }
                &[aria-expanded="true"] {
                    &:before {
                        content: "\e01a";
                    }
                }
            }
        }
        > .menu-main-1__header {
            a {
                text-transform: uppercase;
                font-size: 1.7em;
                font-weight: $light;
                padding-right: 3rem;
            }
        }
        > .menu-main-1__sublevel {
            display: block;
            position: absolute;
            left: 100%;
            top: 0;
            width: calc(118rem - 78rem);
            visibility: hidden;
            transform: scaleX(0);
            transform-origin: 0 0;
            transition: transform ease .2s, visibility ease 0s .2s;
            &[aria-hidden="false"] {
                padding-left: 25px;
                visibility: visible;
                transform: scaleX(1);
                transition: transform ease .4s, -webkit-transform ease .4s;
            }
        }
        &.menu-main-1__item--active {
            background: $color-2--5;
        }
        &:hover, &:focus {
            background: $color-2--5;
        }
        &:last-child {
            border: none;
        }
    }
    .menu-main-1__closing-button {
        display: none;
    }
}

// Level 3
.menu-main-1__listitems-3 {
    border-right: 5px solid $color-2--5;
    > li {
        padding: 1.05em 1.7em;
        > .menu-main-1__header {
            a {
                text-transform: uppercase;
                font-size: 1.7em;
                font-weight: $light;
                padding-right: 3rem;
                text-transform: capitalize;
            }
        }
        > .menu-main-1__sublevel {
            display: block;
            position: absolute;
            left: 100%;
            top: 0;
            width: calc(118rem - 78rem);
            visibility: hidden;
            transform: scaleX(0);
            transform-origin: 0 0;
            transition: transform ease .2s, visibility ease 0s .2s;
            &[aria-hidden="false"] {
                padding-left: 25px;
                transition: transform ease .4s, visibility ease 0s 0s;
                visibility: visible;
                transform: scaleX(1);
            }
        }
        &.menu-main-1__item--active {
            background: $color-2--5;
        }
        &:hover, &:focus {
            background: $color-2--5;
        }
    }
}

// Level 4
.menu-main-1__listitems-4 {
    padding: 1.05em 1.7em;
    > li {
        margin: 1.2em 0;
        > .menu-main-1__header {
            a {
                font-weight: $light;
                font-size: 1.4em;
                @include default-icons-before('\e017', $margin: 0 5px 0 0, $size: 0.8em, $color: #1064a5, $va: middle, $font: 'icons-default');
                &:hover {
                    color: $color-1--4;
                    text-decoration: underline;
                    &:before {
                        color: $color-1--4;
                    }
                }
                span {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
    }
}
