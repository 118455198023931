// @name Form
form {
    display: block;
    margin: 2em 0 3em;
    &:first-child {
        margin-top: 0;
    }
}

// @name Field wrapper
// @description For wrap set of field (field + label + text...)
.form__field-wrapper {
    margin: 1.5em 0 2.5em;
    &.grid {
        //margin-bottom: 0;
        margin-top: -1.5em;
        margin-bottom: -2em;
    }
    .form__field-wrapper {
        //margin-top: 0;
    }
}

// @name Form in Powermail confirmation page
.confirmation {
    form {
        display: inline-block;
        margin: 0;
    }
}

// @name Fieldset
fieldset {
    margin-top: 3em;
    margin-bottom: 6em;
    border: none;
    position: relative;
    background: transparent;
    min-width: inherit; // trick for chrome
    &:first-child {
        margin-top: 0;
    }
}

// @name legend
.legend,
.rte .legend,
legend {
    display: block;
    padding: 0;
    color: $color-black;
    text-align: left;
    font-style: normal;
    font-size: 2em;
    font-weight: $light;
    white-space: normal;
    width: 100%;
    margin-bottom: 1em;
}

// @name Input, textarea, select
$input__font-size: 1.4;
input, textarea, select {
    font-size: #{$input__font-size}em;
    font-family: $typo-1;
    background: $color-white;
    width: 100%;
    border: 1px solid $color-2--1;
    padding: em(.95, $input__font-size) em(1, $input__font-size);
    color: $color-2--2;
    &:disabled {
        background: $color-3--1;
        color: $color-3--2;
    }
    &:focus {
        outline: 1px dashed $color-1--2;
        outline-offset: 0;
    }
}

input, textarea {
    &:read-only {
        background: $color-3--1;
        color: $color-3--2;
    }
}

textarea {
    height: em(10, 1.4);
}

// @name Select
select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    background: $color-white url("../Images/form/select_right.png") no-repeat 100% 50%;
    cursor: pointer;
    padding-right: em(3, 1.4);
    &:disabled {
        background: $color-3--1 url("../Images/form/select_right-3.png") no-repeat 100% 50%;
        cursor: inherit;
    }
    &::-ms-expand {
        display: none;
    }
}

// @name Label
$c-label: $color-2--2;
$label-font-size: 1.6;
.label,
.rte .label,
label {
    display: block;
    font-size: #{$label-font-size}em;
    font-weight: $bold;
    color: $c-label;
    margin: 0 0 em(.5, $label-font-size);
    cursor: pointer;
    .text-help {
        font-size: em(1.2, $label-font-size);
    }
    .required {
        font-size: 1em;
    }
    // field nested
    input, select, textarea {
        margin-top: em(.5, $label-font-size);
        font-size: #{$input__font-size}rem;
        padding: em(0.85, $label-font-size) em(1, $label-font-size);
    }
}

// @name text label
// @description style label when label is used in wrapper
.text-label {
    display: block;
    margin: 0 0 em(.5, $label-font-size);
}

.nolabel {
    > label,
    > legend {
        @extend .ghost;
    }
}

// @name Help text
// @state .text-help--inline - Indicates the inline positioning
.text-help {
    font-weight: $light;
    font-size: 1.2em;
    display: block;
    color: $color-3--3;
    margin: .5em 0 .5em;
    &.text-help--inline {
        display: inline;
    }
}

// @name Required field
.required {
    color: $color-red;
    font-size: 1.4em;
    font-weight: $normal;
    font-style: normal;
}

// @name field-group
.form__field-group {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border: 1px solid $color-3--2;
    > * {
        flex-basis: 100%;
    }
    input {
        margin: 0;
        border-color: transparent;
    }
}

// @name before-field
// @description Information before and after field
$attached-field__font-size: 1.3;
.form__attached-field {
    background: $color-3--1;
    flex: 1 0 auto;
    font-size: em($attached-field__font-size, $label-font-size);
    font-weight: $normal;
    font-style: italic;
    padding: 1.1rem 1rem;

}

// @name Geocode input text
// @description add a "localization me" button with JS, and report user address in the input field
.geocode {
    position: relative;
    .geocode--wrapper {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 4px;
        background: none;
        .geocode--wrapper__button {
            &:before {
                content: '\e055';
            }
        }
    }
}

// @name Datepicker extend
// @description Extend _datepicker.scss : Move the datepicker opener and change datepicker size
form {
    .form__field-datewrapper {
        position: relative;
        .date-picker-control {
            position: absolute;
            bottom: 0;
            right: 0;
            margin: 10px;
        }
    }
}

.date-picker {
    table {
        font-size: 1.5em;
    }
}

// @name Datepicker extend
// @description Extend _jquery.timepicker.scss : change timepicker size
.ui-timepicker-wrapper {
    font-size: 1.5em;
}

// @name Parsley styling
// @description Style for form error generated by parlsey (powermail)
.parsley-errors-list {
    p {
        margin: 5px 0 15px;
        color: $alert-title;
        font-style: italic;
        font-size: 1.2em;
        @include default-icons-before('\e00f', 0, 16px, $alert-title);
    }
}

// @name Input radio and checkbox
// @description
// @state .column-2 - Indicates the column positioning
// @state .radio-checkbox--inline - Indicates radio or checkbox positioning side by side. Works only on one line.
.radio, .checkbox {
    $radio-checkbox__legend__font-size: 1.4;
    clear: both;
    margin: 1.5em 0 2.5em;
    @extend .clear-fix;
    &:first-child {
        margin-top: 1.5em;
    }
    &.radio-checkbox--inline {
        div {
            display: inline-block;
            vertical-align: top;
            margin-top: 0;
            margin-right: 1.2em;
            margin-bottom: 1.3em;
        }
        legend {
            margin-bottom: em(1.8, $radio-checkbox__legend__font-size);
        }
    }
    &.column-2 {
        div {
            display: inline-block;
            vertical-align: top;
            width: 47.4%;
            &:nth-child(even) {
                margin-right: 1%;
            }
            &:nth-child(odd) {
                margin-left: 1%;
            }
        }
        legend + div, legend + div + div {
            margin-top: 0;
        }

    }
    div {
        margin: 1.1em 0;
        &:nth-of-type(1) {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    legend {
        margin: em(.5, $radio-checkbox__legend__font-size) 0 em(0.5, $radio-checkbox__legend__font-size);
        font-size: #{$radio-checkbox__legend__font-size}em;
        .required {
            font-size: 1em;
        }
    }
    label {
        font-weight: $light;
        display: inline-block;
        position: relative;
        line-height: em(1.4, $label-font-size);
        padding: 0 0 0 em(2, $label-font-size);
        margin: 0;
        &:before {
            content: "";
            position: absolute;
            display: inline-block;
            background-color: #fff;
            border: 1px solid $color-3--2;
            font-size: 1em; // 13px
            width: 14px;
            height: 14px;
            left: 0;
            top: 0;
            speak: none;
        }
        &:after {
            position: absolute;
            line-height: normal;
            content: "";
            speak: none;
            background: transparent;
            transition: all ease .5s;
        }
    }
    input[type=radio], input[type=checkbox] {
        position: absolute;
        left: -9999px;
        &:disabled + label {
            cursor: default;
            color: lighten($c-label, 70%);
        }
        &:disabled + label:before {
            border-color: $color-3--2;
            cursor: default;
        }
        &:focus {
            & + label {
                outline: 1px dashed $color-1--2;
                outline-offset: 2px;
            }
        }
    }
    // Checkbox
    input[type=checkbox] {
        &:checked + label:after, &:checked:disabled + label:after {
            top: 3px;
            left: 3px;
            width: 8px;
            height: 8px;
            background: $color-1--2;
        }
        &:checked:disabled + label:after {
            background: $color-3--2;
        }
        // Here you can change svg image
        &:checked:disabled + label:after {

        }
    }

    // Radio
    input[type=radio] {
        &:checked + label:after, &:checked:disabled + label:after {
            top: 3px;
            left: 3px;
            width: 8px;
            height: 8px;
            background: $color-1--2;
            border-radius: 50%;
            display: block;
        }
    }
}

// Radio
.radio {
    label {
        &:before {
            border-radius: 50%;
        }
    }
}

// Specific form

// input file resume
.rte .powermail_fieldwrap_file_list, .powermail_fieldwrap_file_list {
    > li {
        margin: .5em 0;
        font-size: 1.2em;
        color: $color-2--2;
        @include default-icons-absolute-before('\e027', 14px, #00bbdb, 3px, inherit, inherit, 0);
        padding-left: 18px;
        &:before {
            top: 50%;
            transform: translateY(-50%);
        }
        &.powermail_fieldwrap_file_list__delete {
            &:before {
                content: inherit;
                display: none;
            }
            padding-left: 0;
        }
    }
    .button-1 {
        &:before {
            content: "\e002";
        }
    }
}

// @name Dark form
// @description Form with dark background
.form--dark {
    background: $color-3--3;

    // @name legend
    legend {
        color: $color-white;
    }

    // @name Label
    label {
        color: $color-white;
    }

    .text-help {
        color: $color-3--1;
    }

    .required {
        color: lighten($color-red, 30%);
    }

    // @name Input, textarea, select
    input, textarea, select {
        background: darken($color-3--3, 10%);
        border-color: darken($color-3--3, 20%);
        color: $color-white;
        &:focus {
            outline-color: $color-3--1;
            outline-offset: 0;
        }
    }

    // @name Select
    select {
        background: darken($color-3--3, 10%) url("../Images/form/select_right-2.png") no-repeat 100% 50%;
        &:disabled {
            background: darken($color-3--3, 10%) url("../Images/form/select_right-3.png") no-repeat 100% 50%;
            color: lighten($color-3--3, 10%);
        }
    }

    // input file resume
    .rte .powermail_fieldwrap_file_list, .powermail_fieldwrap_file_list {
        > li {
            color: $color-white;
        }
    }
}
