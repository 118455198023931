.home-kh {
  &-left {
    .kh-left-info {
      width: 100%;
      height: auto;
      padding: 10px 15px 30px;
      position: static;
      display: block;
      text-align: center;
    }
  }
  &-right {
    .kh-right-item {
      .kh-right-info {
        position: static;
        a {
          font-size: 1.2em;
        }
        p {
          a {
            font-size: 1.3em;
          }
        }
      }
    }
  }
  .button-2 {
    display: none;
  }
}