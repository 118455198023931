// @name Cross menu
.footer {
    .menu-cross {
        flex-grow: .9;
        flex-shrink: .9;
    }
}

.menu-cross {
    text-transform: uppercase;
    overflow: hidden;
    ul {
        width: 49%;
        height: 100%;
        position: relative;
        display: inline-block;
        padding-top: 2.4em;
        padding-left: 7.5em;
        vertical-align: top;
        &:before {
            content: '';
            width: 1px;
            height: 100%;
            @include background-opacity($color-white, $opacity: 0.2);
            position: absolute;
            top: 0;
            left: 0;
        }
        li {
            padding: .6em 1.5em;
            @include default-icons-absolute-before('\e011', 1.6em, $color-1--2, 5px, inherit, inherit, 0);
            a {
                display: block;
                color: $color-white;
                text-decoration: none;
                font-size: 1.2em;
                line-height: 1.2em;
                &:hover, &:focus {
                    color: $color-1--2;
                    span {
                        text-decoration: underline;
                    }
                }
            }
            &.active a {
                color: $color-white;
                text-decoration: none;
                font-weight: $bold;
            }
        }
    }
}
