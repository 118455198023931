.home-agenda {
  &__top {
    height: auto;
    &-col {
      width: 100%;
      float: none;
      &:nth-child(2) {
        .home-agenda__wrapper {
          padding: 30px 100px 30px 215px;
          &-date {
            position: absolute;
            left: 90px;
            z-index: 1;
            top: 33px;
          }
        }
        &:before {
          content: none;
        }
      }
    }
  }
  &__bottom {
    width: 100%;
    &-col {
      width: 49.8%;
      margin: 1px 0.1%;
      flex: 1 1 48.6%;
      &:nth-child(odd) {
        margin-left: 0;
      }
      &:nth-child(even) {
        margin-right: 0;
      }
    }
  }
}