.ddm.ddm__content + .ddm.ddm__content {
    margin-top: -3.9em;
}

.ddm.ddm__content {
    margin: 4em 0 5em 0;
    .ddm__title {
        font-size: 1em;
        margin-bottom: 0;
    }
    .ddm__sub-level {
        position: static;
        top: inherit;
        right: inherit;
        z-index: inherit;
        display: block;
        height: auto;
        overflow: hidden;
        max-height: 0;
        background: $color-4--2;
        //transition: all ease .2s;
    }
    &.ddm--active {
        .ddm__sub-level {
            padding: 3em;
            max-height: 100%;
            //transition: all ease-in-out 1s;
        }
    }
}

.ddm__content {
    .ddm__button {
        display: block;
        width: 100%;
        text-align: left;
        font-size: 2em;
        font-family: $typo-3;
        font-weight: $light;
        background: $color-white;
        border-bottom: 5px solid $color-1--2;
        padding: em(.5, 2) em(4, 2) em(.5, 2) em(1, 2);
        position: relative;
        transition: all ease .3s;
        white-space: normal;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: -5px;
            width: 40px;
            background: $color-white;
            z-index: 1;
            border-bottom: 5px solid $color-1--3;
        }
        @include default-icons-absolute-before('\e000', em(1, 2), $color-1--3, 50%, em(1.5, 1), inherit, inherit);
        &:before {
            z-index: 2;
            transform: translateY(-50%);
        }
    }
    &.ddm--active {
        .ddm__button {
            background: $color-2--2;
            color: $color-white;
            border: none;
            &:before {
                content: "\e001";
                color: $color-white;
            }
            &:after {
                bottom: 0;
                border: none;
                background: $color-2--3;
            }
        }
    }
}
