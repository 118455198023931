.home-main-slider {
    position: relative;
    z-index: 1;
    margin-bottom: 50px;
    .item {
        border-bottom: 4px solid $color-2--2;
        img {
            width: 100%;
        }
    }
    .info-block {
        width: 35.4em;
        height: 35.4em;
        padding: 4.2em 4.7em;
        position: absolute;
        bottom: -220px;
        left: calc(50% - 177px);
        text-align: center;
        font-family: $typo-2;
        background: $color-white;
        border-radius: 50%;
        &:before {
            width: 377px;
            height: 384px;
            content: '';
            position: absolute;
            z-index: -1;
            left: -262px;
            top: -140px;
            background: url("../Images/bg/slider-info-bg-left.png") no-repeat;
        }
        &:after {
            width: 377px;
            height: 384px;
            content: '';
            position: absolute;
            z-index: -1;
            right: -262px;
            top: -140px;
            background: url("../Images/bg/slider-info-bg-right.png") no-repeat;
        }
        > p {
            position: relative;
            padding-bottom: 20px;
            font-size: 1.6em;
            color: $color-2--3;
            font-weight: $bold;
            text-transform: uppercase;
            &:after {
                position: absolute;
                bottom: 10px;
                left: -webkit-calc(50% - 16.5px);
                left: calc(50% - 16.5px);
                content: '';
                width: 33px;
                height: 1px;
                background: #ed6d07
            }
        }
        h2 {
            a {
                font-size: em(2.9, 1.5);
                color: $color-2--3;
                font-weight: $light;
            }
        }
    }
    .pagerCarrousel1 {
        position: absolute;
        left: calc(50% - 63px);
        z-index: 100;
        bottom: -100px;
    }
    .prevNextCarrousel1 {
        li {
            position: absolute;
            z-index: 100;
            display: inline-block;
            img {
                display: none;
            }
            &.prevCarrousel1 {
                left: calc(50% - 206.5px);
                bottom: 73px;
                button {
                    @include default-icons-before('\e01a', $margin: 0, $size: 2.5em, $color: $color-white, $va: middle, $font: 'icons-default');
                }
            }
            &.nextCarrousel1 {
                right: calc(50% - 209.5px);
                bottom: 73px;
                button {
                    @include default-icons-before('\e01b', $margin: 0, $size: 2.5em, $color: $color-white, $va: middle, $font: 'icons-default');
                }
            }
        }
    }
    button.buttonStop.modeplay {
        position: absolute;
        bottom: -97px;
        right: calc(50% - 30px);
        z-index: 100;
        img {
            width: 7px;
        }
    }
}
