.home-partners {
    margin-bottom: 65px;

    .title-3 {
        margin-top: 30px;
    }

    .home-partners__slider {
        position: relative;
        width: 96%;
        margin: 0 auto;

        .listItems {
            display: flex;
            justify-content: space-between;

            .item {
                flex: 1;
                padding: 0 15px;
                vertical-align: top;
                text-align: center;
                white-space: normal;

                .item-image {
                    height: 110px;
                    display: flex;
                    border-bottom: 5px solid $color-2--1;
                    align-items: center;
                    justify-content: center;

                    img {
                        height: 65%;
                    }
                }

                .item-title {
                    display: block;
                    padding: 15px 5px;
                    font-size: 1.3em;
                    color: $color-2--3;
                    font-weight: $light;
                    font-family: $typo-2;
                }
            }
        }

        .prevNextCaroussel2 {
            .prevCaroussel2 {
                position: absolute;
                top: calc(50% - 20px);
                left: -2%;
            }

            .nextCaroussel2 {
                position: absolute;
                top: calc(50% - 20px);
                right: -2%;
            }

            .prevCaroussel2,
            .nextCaroussel2 {
                button {
                    img:nth-child(1) {
                        display: block;
                    }

                    img:nth-child(2) {
                        display: none;
                    }

                    &.disabled {
                        img:nth-child(1) {
                            display: none;
                        }

                        img:nth-child(2) {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
