.home-foundation {
    width: 100%;
    height: 394px;
    padding: 0;
    font-family: $typo-2;
    .title-3 {
        max-width: 122rem;
        margin: 0 auto 20px;
        margin-top: 1.6em;
    }
    &__col {
        width: 33.33%;
        height: 100%;
        position: relative;
        float: left;
        .home-foundation__wrap-image {
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
                font-family: 'object-fit: cover;';
            }
        }
        .home-foundation__wrap {
            width: 100%;
            height: 100%;
            padding: 0 5%;
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            align-items: center;
            justify-content: center;
            background: $color-1--2;
            opacity: 0;
            transition: opacity 0.3s ease;
            span {
                font-size: 1.8em;
                color: $color-2--2;
                font-weight: $bold;
                z-index: 1;
            }
            &:before {
                content: '';
                width: 90px;
                height: 90px;
                position: absolute;
                left: -15px;
                background: $color-1--2;
                border-radius: 50%;
            }
        }
        a:hover, a:focus {
            .home-foundation__wrap {
                opacity: 1;
            }
        }
    }
    &__col-center {
        .home-foundation__col-small {
            width: 50%;
            height: 50%;
            position: relative;
            float: left;
            .home-foundation__wrap-image {
                width: 100%;
                height: 100%;
            }
        }
    }
    &__col-right {
        padding: 45px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background: $color-2--2;
        .home-foundation__right-wrap {
            padding: 30px 0 20px;
            span {
                display: inline-block;
                width: 65%;
                vertical-align: middle;
            }
            &:nth-child(2) {
                border-top: 1px solid #0958aa;
                border-bottom: 1px solid #0958aa;
            }
            a {
                font-size: 1.8em;
                color: $color-white;
                font-weight: $bold;
                .home-foundation__right-img {
                    width: 70px;
                    display: inline-block;
                    vertical-align: middle;
                    text-align: center;
                }
                .plus {
                    width: 33px;
                    height: 33px;
                    display: inline-block;
                    float: right;
                    margin: 0 auto 15px;
                    border: 1px solid $color-1--3;
                    @include default-icons-before('\e08a', $margin: 0, $size: 1.1em, $color: $color-1--3, $va: middle, $font: 'icons-default');
                }
                &:hover,
                &:focus {
                    text-decoration: none;
                    span {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
