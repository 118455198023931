.home-actus {
  &__left {
    .home-actus__info {
      width: 100%;
      height: auto;
      position: static;
      padding: 20px;
      text-align: center;
      border-radius: 0;
      align-items: center;
      > a {
        &:after {
          left: calc(50% - 16.5px);
        }
      }
    }
  }
  &__right {
    height: auto;
    margin-bottom: 0;
    .column-bloc__listitems {
      width: 100%;
      float: none;
      .column-bloc__wrapper {
        height: auto;
        padding: 6em 2.5em;
      }
    }
  }
}