.home-actus {
    height: 410px;
    margin-top: 40px;
    .column-bloc {
        padding-left: 0;
    }
    &__col {
        width: 50%;
        float: left;
        height: 100%;
    }
    &__left {
        position: relative;
        > a img {
            width: 100%;
            height: auto;
        }
        .home-actus__info {
            width: 296px;
            height: 296px;
            padding: 4.2em 1.7em 4.2em 9.7em;
            position: absolute;
            top: calc(50% - 148px);
            left: -96px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            text-align: left;
            font-family: "Roboto Condensed", sans-serif;
            background: #fff;
            border-radius: 50%;
            > a {
                font-size: 1.3em;
                color: $color-2--2;
                font-weight: $bold;
                text-transform: uppercase;
                position: relative;
                &:after {
                    position: absolute;
                    bottom: -7px;
                    left: 0;
                    content: '';
                    width: 33px;
                    height: 1px;
                    background: #ed6d07;
                }
            }
            p {
                display: block;
                width: 182px;
                margin-top: 20px;
                a {
                    font-size: 1.8em;
                    color: $color-black;
                    font-weight: $light;
                }
            }
        }
    }
    &__right {
        height: 410px;
        margin-bottom: 0;
        img {
            width: 100%;
        }
        .column-bloc__listitems {
            width: 50%;
            float: left;
            .bloc-news__picture {
                img {
                    width: 100%;
                    height: auto;
                    display: block;
                }
                &:before {
                    height: 32px;
                    background: url("../Images/bg/bg-circle-home.png") repeat center;
                }
            }
            .column-bloc__wrapper {
                height: 197px;
            }
        }
    }
    > .button-2 {
        width: 100%;
        padding-top: 1.17857em;
        padding-bottom: 1.17857em;
        margin-top: 0;
        text-align: center;
        &:before {
            content: none;
        }
    }
}
