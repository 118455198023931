// @name Tools
// @description Tools on page and sharing on social media network
.tools {
    display: inline-block;
    vertical-align: middle;
    width: 29.5%;
    text-align: right;
    svg {
        width: 1.6em;
        height: 1.6em;
        fill: $color-1--3;
    }
    .button-2 {
        border: none;
        background: $color-1--2;
        padding: 0.834em 1.167em;
        &:before {
            content: "\e02d";
            color: $color-white;
        }
    }
}

.tools__listitems {
    margin: 0 -.5em;
}

.tools__item {
    display: inline-block;
    vertical-align: middle;
    margin: 0 .5em;
    > a,
    > button {
        font-size: 1em;
    }
}

// @name Share page
.ddm.share-page {
    .ddm__sub-level {
        display: block;
        visibility: hidden;
        top: 100%;
        left: inherit;
        right: 0;
        overflow: hidden;
        z-index: 2;

    }
    .ddm__wrapper {
        transform: translateY(-110%);
        transition: all ease .1s;
    }
    &.ddm--active {
        .ddm__sub-level {
            visibility: visible;
        }
        .ddm__wrapper {
            transform: translateY(0);
            transition: all ease .4s;
        }
    }
}

.share-page {
    .ddm__sub-level {
        text-align: left;
        width: 13em;
    }
    .ddm__wrapper {
        background: $color-1--2;
        color: color-contrast($color-1--2);
        padding: 1em 1.5em;
        a {
            color: color-contrast($color-1--2);
        }
    }
}

.share-page__item {
    font-size: 1.3em;
    margin: .5em 0;
    @include default-icons-before('\e098', 0 5px 0 0, em(1.4, 1.4), color-contrast($color-1--2), em(-.2, 1.4));
    &.email {
        &:before {
            content: "\e028";
        }
    }
    &.facebook {
        &:before {
            content: "\e098";
        }
    }
    &.twitter {
        &:before {
            content: "\e09a";
        }
    }
    &.google {
        &:before {
            content: "\e0a4";
        }
    }
    &.instagram {
        &:before {
            content: "\e0a1";
        }
    }
    &.linkedin {
        &:before {
            content: "\e0a2";
        }
    }
    &.viadeo {
        &:before {
            content: "\e0a3";
        }
    }
    &.youtube {
        &:before {
            content: "\e09d";
        }
    }
}
