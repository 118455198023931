// @name bloc event

// @name Bloc event in column
.column-bloc.bloc-event {
    img {
        max-width: 100%;
        height: auto;
        margin-bottom: 2em;
    }
    .bloc-event__listitems {
        padding: 2em 3em 3em;
        background: $color-3--1;
        text-align: center;
        .category {
            position: relative;
            padding-bottom: 20px;
            font-size: 1.3em;
            &:after {
                position: absolute;
                bottom: 10px;
                left: calc(50% - 16.5px);
                content: '';
                width: 33px;
                height: 1px;
                background: $color-1--3;
            }
        }
        a {
            color: $color-black;
            &:hover, &:focus {
                color: $color-2--2;
            }
            &:focus {
                outline: 1px dashed $color-2--2;
            }
        }
    }
    .bloc-event__wrapper-date {
        margin-bottom: 2em;
    }
    .bloc-event__title {
        $bloc-news__title__font-size: 1.8;
        font-family: $typo-3;
        font-weight: $light;
        font-size: #{$bloc-news__title__font-size}em;
        margin: 0 0 em(1.5, $bloc-news__title__font-size);
        color: $color-black;
    }
    .date-1 {
        height: 62px;
        position: relative;
        color: $color-2--3;
        span:first-child + time {
            margin-left: 0;
        }
        .day {
            font-size: 2.8em;
        }
        .month {
            font-size: 1.2em;
            text-transform: uppercase;
        }
        time {
            width: 62px;
            height: 62px;
            margin: 0;
            position: absolute;
            padding-top: 1em;
            border-radius: 50%;
            &:nth-child(2) {
                left: calc(50% - 57px);
                background: $color-1--1;
            }
            &:nth-child(3) {
                right: calc(50% - 57px);
                background: $color-1--2;
            }
        }
    }
}
