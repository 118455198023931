// @name Header page
.header {
    &__right {
        height: auto;
        right: 7.3rem;
        top: 2.1rem;
        > div {
            width: auto;
            height: 5rem;
            padding: 0 0.3rem;
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 0;
                font-size: 0.9rem;
                img {
                    width: auto;
                    height: 1.6rem;
                    margin-bottom: 0.7rem;
                }
            }
        }
        &-emergencies {
            max-width: 5rem;
        }
        &-recherche {
            img {
                width: auto;
                height: 1.6rem;
            }
            p {
                font-size: 0.9rem;
            }
            .search-box {
                margin-right: 0;
            }
            .button-1 {
                padding: 0;
            }
        }
    }
}
