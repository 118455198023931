.home-agenda {
  &__top-col {
    &:nth-child(2) {
      .home-agenda__wrapper {
        padding: 15px 35px;
        text-align: center;
        &-date {
          position: static;
          .date-1 {
            time {
              &:nth-child(2) {
                left: calc(50% - 57px);
              }
              &:nth-child(3) {
                left: inherit;
                right: calc(50% - 57px);
              }
            }
          }
        }
        .category {
          &:after {
            left: calc(50% - 66.5px);
          }
        }
      }
      .button-2 {
        width: 100%;
      }
    }
  }
  &__bottom-col {
    width: 100%;
    margin: 0 0 0.5%;
    flex: 1 1 100%;
    .home-agenda__wrapper {
      width: 100%;
    }
  }
}