.home-foundation {
  .title-3 {
    width: 100%;
    max-width: 96rem;
    padding: 0 0.5em;
  }
  &__col {
    width: 50%;
    &-right {
      width: 100%;
    }
  }
}
