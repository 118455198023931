.home-consult {
  &__wrapper {
    height: auto;
    &-left,
    &-right {
      width: 100%;
      float: none;
    }
    &-left {
      height: 413px;
    }
    &-right {
      margin-top: 25px;
      .form__field-wrapper {
        margin: 0;
        display: inline-block;
        label {
          p {
            padding-left: 0;
          }
          select {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
          }
        }
        &:nth-child(1) {
          float: left;
        }
        &:nth-child(2) {
          float: right;
        }
      }
      button.button-1 {
        top: 20px;
        left: calc(50% - 143px);
        margin-left: 0;
      }
    }
  }
}