// @name Search box
// @description Search module
.search-box {
    width: auto;
    margin-right: 4em;
    .search-box__fields-wrapper, .search-box__button-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
    .search-box__fields-wrapper {
        width: 100%;
    }
    .search-box__button-wrapper {
        padding-left: 1em;
    }
    // Drop down menu
    .ddm {
        > .ddm__sub-level {
            z-index: 102;
            padding: 1em;
            //background: $color-3--2;
        }
    }
}

.menu-main-1__wrapper {
    .search-box {
        display: block;
        float: none;
        margin: 5rem 0 2rem;
        .search-box__fields-wrapper {
            width: initial;
        }
        .search-box__fields-wrapper,
        .search-box__button-wrapper {
            display: inline-block;
        }
    }
    .ddm.ddm--on .ddm__title {
        display: none;
    }
    .ddm .ddm__sub-level {
        display: block;
        padding: 0;
        margin: 0 auto;
        position: static;
        background: none;
    }
}
