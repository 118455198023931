// @name Search box
// @description Search module
.search-box {
    float: right;
    padding: 0;

    form {
        margin: 0;
    }
    label {
        margin-right: em(0.5, 1.8);
        white-space: nowrap;
        font-size: 1.8em;
    }

    .search-box__fields-wrapper, .search-box__button-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    // @name Search Field wrapper
    // @state .search__field-wrapper--in-line - Indicates label and field are side by side.
    .search-box__field-wrapper {
        width: 100%;
        &.search-box__field-wrapper--in-line {
            display: table;
            width: 100%;
            label {
                display: table-cell;
                vertical-align: middle;
                text-align: right;
            }
            input, select {
                display: table-cell;
                vertical-align: middle;
                width: 100%;
            }
        }
    }

    .search-box__fields-wrapper {
        width: 100%;
    }
    .search-box__button-wrapper {
        background: $color-white;
        padding: 0.8em 1em;
        margin-left: 15px;
        .button-1 {
            padding: 0;
            svg {
                fill: $color-2--2;
                width: 1.5em;
                height: 1.5em;
            }
        }
    }

    .search-box__close-wrapper {
        padding: 0.8em 1em;
        margin-right: 15px;
        .search-box__close {
            padding: 0;
            svg {
                fill: $color-white;
                width: 3.5em;
                height: 3.5em;
            }
        }
    }

    // Drop down menu
    .ddm {
        position: static;
        > .ddm__sub-level {
            align-items: center;
            position: fixed;
            top: 0;
            right: 3em;
            left: 0;
            width: 100%;
            height: 96px;
            z-index: 102;
            padding: 1em;
            background: $color-2--2;
        }
    }
}

// @name Search list
.pertinence {
    font-size: 1.2em;
    span {
        font-size: em(1, 1.2);
    }
}

.menu-main-1__wrapper {
    .search-box {
        display: none;
    }
    .search-box .search-box__field-wrapper.search-box__field-wrapper--in-line {
        input {
            width: 220px;
            height: 50px;
            border: 1px solid #c8c8c8;
        }
    }
    .search-box .search-box__button-wrapper {
        vertical-align: middle;
        button {
            width: 50px;
            height: 50px;
            background: none;
            border: 1px solid #03498b;
            svg {
                width: 1.5em;
                height: 1.5em;
                fill: #03498b;
            }
        }
    }
}

@media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, .pertinence span {
        font-size: 1.1em;
        font-weight: $bold;
    }
}

meter {
    -webkit-appearance: meter;
    -moz-appearance: meterbar;
    width: em(6, 1.2);
    height: em(1, 1.2);
    border: 1px solid darken($color-3--1, 20%);
    &::-webkit-meter-bar {
        background: darken($color-3--1, 20%);
        height: 100%;
        width: 100%;
    }
    &::-webkit-meter-optimum-value {
        height: 100%;
        background: $color-1--2;
    }
    &:-moz-meter-optimum {
        background: darken($color-3--1, 20%);
        border: none;
    }
    &:-moz-meter-optimum::-moz-meter-bar {
        background: $color-1--2;
    }
    &:-moz-meter-sub-optimum::-moz-meter-bar {
        background: antiquewhite;
    }
    &:-moz-meter-sub-sub-optimum::-moz-meter-bar {
        background: steelblue;
    }
}

@media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, meter {
        display: none;
    }
}
