// @name Subtitle or category
$category__font-size: 1.2;
.subtitle, .category {
    font-family: $typo-2;
    font-weight: $bold;
    font-size: #{$category__font-size}em;
    text-transform: uppercase;
    color: $color-2--2;
}

// @name caption
// @description Default caption
.caption {
    font-size: 1.3em;
    font-weight: $light;
    font-style: italic;
    font-family: $typo-2;
    color: $color-3--3;
}

// @name Date
// @description Default date
.date {
    $date__font-size: 1.2;
    font-size: #{$date__font-size}em;
    font-weight: $normal;
    font-family: $typo-2;
    color: $color-3--3;
    margin: em(0.6, $date__font-size) 0;
    &.date--big {

    }
}

// @name Date 1
// @description Date in event
.date-1 {
    font-family: $typo-2;
    color: $color-2--3;
    text-align: center;
    margin-bottom: 2em;
    time {
        width: 62px;
        height: 62px;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        padding-top: 1em;
        border-radius: 50%;
        &:nth-child(2) {
            background: $color-1--1;
        }
        &:nth-child(4) {
            margin-left: -11px;
            background: $color-1--2;
        }
    }
    .day, .month {
        display: block;
        line-height: 1;
    }
    .day {
        font-size: 2.8em;
        font-weight: $bold;
    }
    .month {
        font-size: 1.2em;
        font-weight: $normal;
        text-transform: uppercase;
    }
}

// @name Date 2
// @description Date in job
$date-2__font-size: 1.4;
$date-2--big__font-size: 2;
.date-2 {
    font-size: #{$date-2__font-size}em;
    text-transform: uppercase;
    color: $color-1--2;
    margin: 0 0 em(.8, $date-2__font-size);
    time {
        font-weight: $bold;
    }
    &.date-2--big {
        font-size: #{$date-2--big__font-size}em;
        margin: em(.5, $date-2--big__font-size) 0 em(.8, $date-2--big__font-size);
    }
}

// @name Date 3
// @description Date in public market
.date-3 {
    $date-3__font-size: 1.2;
    @include default-icons-absolute-before('\e05b', em(1.2, 1.2), $color-1--2, em(1.3, 1.2), inherit, inherit, 0);
    font-family: $typo-2;
    font-size: #{$date-3__font-size}em;
    font-weight: $bold;
    text-transform: uppercase;
    padding-top: em(1.2, $date-3__font-size);
    padding-left: em(1.8, $date-3__font-size);
    margin-bottom: em(1.2, $date-3__font-size);
    border-top: 1px solid darken($color-3--1, 20%);
    time {
        font-weight: $light;
    }
    &.date-3--big {
        $date-3--big__font-size: 1.6;
        font-size: #{$date-3--big__font-size}em;
        padding-top: em(1.2, $date-3--big__font-size);
        padding-left: em(2, $date-3--big__font-size);
        margin-bottom: em(1.2, $date-3--big__font-size);
        margin-top: em(1.2, $date-3--big__font-size);
        &:before {
            font-size: em(1.6, $date-3--big__font-size);
            top: em(1.3, $date-3--big__font-size);
        }
        br {
            display: none;
        }
    }
}

// @name Date 4
// @description Date in publications bloc
.date-4 {
    $date-4__font-size: 1.5;
    font-size: #{$date-4__font-size}em;
    text-transform: uppercase;
    font-weight: $light;
    margin: 0 0 em(2, $date-4__font-size);
    time {
        & + span + time {
            @include default-icons-before('\e017', 0 5px 0 0, em(1.4, $date-4__font-size), $color-black, em(-.1, 1.4));
        }
    }
}

// @name Media
// Media in publications, public market, albums
.media {
    border-top: 1px solid darken($color-3--1, 20%);
    color: $color-3--3;
    text-transform: uppercase;
    font-size: 1.2em;
    padding-top: em(1.2, 1.2);
}

// @name Hour and place wrapper
.hour-place {
    text-align: center;
}

// @name Hour
// @description Default hour
.hour {
    $hour__font-size: 1.8;
    font-family: $typo-2;
    display: block;
    font-weight: $bold;
    font-size: #{$hour__font-size}em;
    margin-bottom: em(0.5, $hour__font-size);
}

// @name Place
// @description Default place
.place {
    font-size: 1.4em;
    font-family: $typo-2;
    font-weight: $light;
    font-style: italic;
}

// @name Teaser 1
// @description Teaser in list view
.teaser-1 {
    $teaser-1__font-size: 1.3;
    font-size: #{$teaser-1__font-size}em;
    margin: em(.8, $teaser-1__font-size) 0;
}

// @name Teaser 2
// @description Teaser in heading
$teaser-2__font-size: 1.8;
.teaser-2 {
    font-size: #{$teaser-2__font-size}em;
    line-height: em(2.7, $teaser-2__font-size);
    color: $color-3--3;
    font-weight: $light;
    margin: em(1.2, $teaser-2__font-size) 0 em(.5, $teaser-2__font-size);
}

// @name Teaser 3
// @description Teaser in bloc of right column
.teaser-3 {
    $teaser-3__font-size: 1.6;
    font-size: #{$teaser-3__font-size}em;
    font-weight: $normal;
    margin: em(.8, $teaser-3__font-size) 0;
}

// @name status
// @description Status for public market
.status {
    $status__font-size: 1.2;
    font-size: #{$status__font-size}em;
    background: $color-3--1;
    padding: em(.5, $status__font-size);
    font-family: $typo-2;
    font-weight: $light;
    text-transform: uppercase;
    margin: 0 0 em(.8, $status__font-size);
    @include default-icons-before('\e057', 0 5px 0 0, em(1, $status__font-size), $color-3--3, 0);
    &.status--new {
        background: $color-1--2;
        color: $color-white;
        &:before {
            content: "\e093";
            color: $color-white;
        }
    }
    &.status--in-progress {
        background: $color-2--2;
        color: $color-white;
        &:before {
            content: "\e093";
            color: $color-white;
        }
    }
}

// @name Message
// @state error - Display error message
$error__font-size: 1.4;
.message {
    position: relative;
    background: $color-3--1;
    font-size: #{$error__font-size}em;
    font-weight: $light;
    padding: em(3.5, $error__font-size) em(4.2, $error__font-size);
    &:before {
        content: attr(aria-label);
        display: block;
        color: #009fba;
        font-size: em(2, $error__font-size);
        font-weight: $light;
        margin-bottom: em(2, 2);
        margin-left: em(1.5, 1);
    }
    &:after {
        font-family: "icons-default";
        font-size: 1.6em;
        color: #009fba;
        position: absolute;
        top: 35px;
        right: inherit;
        bottom: inherit;
        left: 40px;
        content: "\e083";
        line-height: 1;
    }
    > li {
        padding-left: em(1.5, $error__font-size);
        font-style: italic;
        @include default-icons-absolute-before('\e027', em(1.2, $error__font-size), $color-black, em(.4, 1.2), inherit, inherit, 0);
        margin: em(.5, $error__font-size) 0;
        &:before {
            font-style: normal;
        }
    }
    &.message--error {
        background: lighten($color-red, 45%);
        li {
            color: $color-2--2;
            &:before {
                color: $color-2--2;
            }
    }
        &:before {
            color: $alert-title;
        }
        &:after {
            color: $alert-title;
            content: "\e07f";
        }
    }
    &.message--succes {
        background: lighten($color-green, 65%);
        li {
            color: $color-2--2;
            &:before {
                color: $color-2--2;
            }
        }
        &:before {
            color: $color-green;
        }
        &:after {
            color: $color-green;
            content: "\e057";
        }
    }
}

// @name No result
.no-result {
    font-size: 1.5em;
    font-weight: $light;
    text-align: center;
}
