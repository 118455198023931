// @name Videos
.videos {
    margin: 4em 0 7em;
}

.video__title {
    $video__title__font-size: 1.6;
    font-family: $typo-2;
    font-weight: $bold;
    font-style: italic;
    font-size: #{$video__title__font-size}em!important;
    color: $color-3--3!important;
    margin-bottom: em(1.5, $video__title__font-size);
    padding-left: em(1, $video__title__font-size);
}

.videos__item {
    position: relative;
    width: 100%;
    margin: 0 auto 25px;
    video {
        width: 100%;
    }
    iframe,
    .iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.videos__ratio {
    display: block;
    width: 100%;
    height: auto;
}

.ce-textpic {
    .videos,
    .videos__item {
        margin: 0;
    }
}

// @name Videos for popin
// @description if only one, no margin
.popin {
    #content {
        .setPos {
            &:only-of-type {
                .videos {
                    margin: 0;
                    .videos__item {
                        &:only-of-type {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
