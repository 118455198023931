// # Global
$domain: "fondationbonsauveur.com";

// ## Typography
$import-google-font-1: "https://fonts.googleapis.com/css?family=Roboto:100,300,300i,400,700,700i";
$import-google-font-2: "https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,700";

$typo-1: 'Roboto', sans-serif;
$typo-2: 'Roboto Condensed', sans-serif;
$typo-3: $typo-1;

$thin: 100;
$extralight: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;

// ## Path
$image-bg-url: "../Images/bg/";
$image-fancybox-url: "../Images/fancybox/";
$font-path: "../Fonts/";

// ## Media queries
$smartphone: 767;
$tablet: 1279;

// ## B&w
$color-black: #000;
$color-white: #fff;
$color-red: #fe0000;
$color-green: #278417;

// ## Alert & required
$alert-background: #fcdcde;
$alert-title: #ff000d;
$alert-color: $color-black;

// ## color-1
$color-1--1: #feee7f;
$color-1--2: #fbc30a;
$color-1--3: #ed6d07;
$color-1--4: #fca22e;

// ## color-2
$color-2--1: #b7bfde;
$color-2--2: #004995;
$color-2--3: #033162;
$color-2--4: #2b5a9d;
$color-2--5: #114072;
$color-2--6: #1064a5;

// ## Color-3
$color-3--1: #f6f6f6;
$color-3--2: #c5c5c5;
$color-3--3: #727272;

// ## Color-4
$color-4--1: #f0f2f7;
$color-4--2: #eaecf3;
$color-4--3: #ccd2e8;
