.menu-cross {
  ul {
    width: 100%;
    padding: 25px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid rgba($color-white, 0.2);
    &:before {
      content: none;
    }
  }
}