// @name Header page
.header {
    @extend .clear-fix;
    position: relative;
    font-family: $typo-2;
    height: 96px;
    &__right {
        height: 100%;
        display: flex;
        position: absolute;
        right: 0;
        top: 0;
        > div {
            height: 100%;
            margin-left: 0.2rem;
            text-align: center;
            vertical-align: top;
            @include background-opacity($color-4--2, $opacity: 0.7);
            &:nth-child(odd) {
                @include background-opacity($color-4--1, $opacity: 0.7);
            }
            a {
                height: 100%;
                display: block;
                padding: em(1.7, 1.2) em(1.7, 1.2) 1em;
                font-size: 1.2em;
                text-transform: uppercase;
                text-decoration: none;
                color: $color-2--2;
                img {
                    display: block;
                    margin: 0 auto 10px;
                }
            }
        }
        &-emergencies {
            max-width: 12rem;
        }
        &-recherche {
            @include background-opacity($color-4--1, $opacity: 0.7);
            img {
                display: block;
                margin: 0 auto 10px;
            }
            p {
                font-size: em(1.2, 1.4);
                color: $color-2--2;
                font-weight: $normal;
            }
            .search-box {
                float: none;
            }
            .ddm.ddm--on .ddm__title,
            .search-box,
            .search-box form,
            .search-box .ddm {
                height: 100%;
            }
            .button-1 {
                padding-top: 0;
                height: 100%;
                background: transparent;
            }
        }
    }
}

// @name Top of content
.top-of-content {
    background: $color-4--2;

}
.top-of-content__wrapper {
    @extend .wrapper-main;
    padding-top: 3.9em;
    padding-bottom: 2.2em;
    border-bottom: 1px solid $color-3--2;
}

// @name Main wrapper
.main {
    width: 100%;
    clear: both;
}

// @name Main section
.section-main {
    &-overflow {
        overflow: hidden;
    }
}

// @name Main section wrappers
.section-main__wrapper {
    @extend .wrapper-main;
    padding-top: 7em;
    padding-bottom: 3em;
}

.section-main__wrapper-2 {
    display: table;
    width: 100%;
    > * {
        display: table-cell;
        vertical-align: top;
    }
}

// @name Main section content
.section-main__content {
    width: 100%;
}

// @name Main section aside
.section-main__aside {
    > .column-bloc {
        *:first-child {
            margin-top: 0;
        }
    }
}

// @name Footer
.footer {
    background: $color-2--3;
    .footer__wrapper {
        @extend .wrapper-main;
        padding-bottom: 6em;
    }
    .footer__wrapper-2 {
        margin: 0 -1.5em;
        display: flex;
        width: 100%;
        > * {
            color: $color-white;
            flex: 1 1 100%;
            margin: 0 1.5em;
        }
    }
}
