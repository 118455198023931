.social-links {
    $this: &;

    .menu-main-1__wrapper & {
        display: flex;
        justify-content: center;
        margin: 30px 0 0;
        width: 100%;
    }

    .header__right & {
        display: none;
    }
}
