.home-foundation {
  &__col {
    width: 100%;
    margin-bottom: 0.4%;
    &-center {
      .home-foundation__col-small {
        width: 49.8%;
        margin: 0 0.2% 0.2%;
        &:nth-child(odd) {
          margin-left: 0;
        }
        &:nth-child(even) {
          margin-right: 0;
        }
      }
    }
    &-right {
      padding: 45px 20px;
      .home-foundation__right-wrap {
        span {
          width: 70%;
        }
        a {
          .plus {
            display: none;
          }
        }
      }
    }
    .home-foundation__wrap {
      text-align: center;
      &:before {
        content: none;
      }
    }
  }
}