// @name Wrapper with wide 940px
.wrapper-main {
    width: 100%;
    max-width: 96rem;
}

// @name Column bloc
// @description Generic bloc in .section-main__aside
$column-bloc__width: 31;
$column-bloc__padding-left: 4;
.column-bloc {
    width: #{$column-bloc__width}em;
    padding-left: #{$column-bloc__padding-left}em;
}
