body.menu-main-1--active {
    overflow-y: hidden;
    .menu-main-1__listitems-0 {
        > .menu-main-1__item {
            overflow-y: auto;
            height: 100%;
        }
    }
}

.menu-main-1__item--no-action-in-desktop {
    .menu-main-1__sublevel {
        display: none;
        &[aria-hidden="false"] {
            display: block;
        }
    }
}

/* Styles */

// All level
.menu-main-1__wrapper {
    z-index: 100;
    position: static;

}
.menu-main-1 {
    .wrapper-main {
        width: 100%;
        padding: 0;
        margin: 0;
    }
}

// Level 0 (displayed in tablet and smartphone)
.menu-main-1__listitems-0 {
    > li {
        //position: absolute;
        position: fixed;
        z-index: 101;
        top: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        transition: transform ease .3s;
        transform: translate(100%, 0);
        width: 100%;
        background: $color-white;

        > .menu-main-1__header {
            display: block;
            background: $color-white;
            padding: 2.3em 0 2.3em 0.5em;
            text-align: left;
            border-bottom: 1px solid $color-4--3;

            > span {
                display: block;
                text-transform: uppercase;
                font-size: 1.6em;
                font-weight: bold;
                color: #03498b;
                padding: 0 0 0 4.5rem;
            }

            .menu-main-1__action {
                text-align: left;
                text-indent: -9999px;
                white-space: nowrap;
                position: absolute;
                top: 1.6em;
                left: -7rem;
                width: 5rem;
                height: 5rem;
                background: $color-2--2;
                transition: left ease .3s, top ease .3s;

                &::before {
                    position: absolute;
                    top: 65%;
                    left: 50%;
                    transform: translateX(-50%);
                    content: "☰";
                    font-size: 1.3em;
                    font-weight: $bold;
                    text-indent: 0;
                    line-height: 0;
                    display: block;
                    margin: 0;
                    color: $color-white;
                }

                &::after {
                    color: $color-white;
                    content: 'Menu';
                    font-family: $typo-1;
                    font-size: 1.4rem;
                    left: 50%;
                    position: absolute;
                    text-indent: 0;
                    top: 0.5rem;
                    transform: translateX(-50%);
                }

                &[aria-expanded = 'true'] {
                    &::before {
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }

        > .menu-main-1__sublevel {
            display: none;

            &[aria-hidden="false"] {
                display: block;
            }

            > .menu-main-1__closing-button {
                display: block;
                top: 20px;
                right: 10px;
            }
        }

        &.menu-main-1__item--active {
            transform: translate(0, 0);

            > .menu-main-1__header {

                .menu-main-1__action {
                    left: 0;
                    top: 7px;
                    background: $color-white;

                    &:before {
                        color: #03498b;
                    }
                }
            }
        }
    }
}

// Level 1
.menu-main-1__listitems-1 {
    background: $color-white;
    > li {
        display: block;
        padding: 26px 0 26px 15px;
        vertical-align: inherit;
        border-bottom: 1px solid $color-4--3;
        text-align: left;
        > .menu-main-1__header {
            > a {
                padding: .5em;
                position: relative;
                &:after {
                    width: 25px;
                    height: 25px;
                    position: absolute;
                    top: 5px;
                    right: 20px;
                    content: ">";
                    line-height: 1.2;
                    font-size: 1.3em;
                    display: block;
                    color: $color-2--2;
                    background: $color-1--4;
                    border-radius: 50%;
                    text-align: center;
                    transform-origin: center;
                }
                &[aria-expanded="true"] {
                    &:after {
                        content: "<";
                        transform: rotate(-90deg);
                        transform-origin: center;
                    }
                }
            }
        }
        > .menu-main-1__sublevel {
            position: static;
            max-height: inherit;
            display: none;
            .menu-main-1__listitems-1__wrapper {
                background: darken($color-1--2, 5%);
                transform: inherit;
                height: auto;
                padding: 0;
            }
            .menu-main-1__closing-button {
                display: none;
            }
            &[aria-hidden="false"] {
                display: block;
                margin-top: 25px;
                max-height: inherit;
                box-shadow: 0px -3px 0px 0px rgba(0,0,0,0.3);
                .menu-main-1__listitems-1__wrapper {
                    transform: inherit;
                    transition: inherit;
                }
            }
        }
        &.menu-main-1__item--active {
            padding-bottom: 0;
            padding-left: 0;
        }
    }
}


// Level 2
.menu-main-1__listitems-2 {
    width: 100%;
    border-right: 0;
    > li {
        // Level 2 and 3
        background: $color-2--2;
        border-bottom: 0;
        .menu-main-1__header {
            .menu-main-1__action {
                right: 4px;
                top: 7px;
                &[aria-expanded="true"] {
                    right: 20px;
                    &:before {
                        content: "\e018";
                    }
                }
            }
        }
        > .menu-main-1__header {
            a {
                padding-top: .5em;
                padding-bottom: .5em;
                padding-left: 1em;
            }
        }
        > .menu-main-1__sublevel {
            display: none;
            position: static;
            width: 100%;
            transform: inherit;
            height: 0;
            transition: inherit;
            background: darken($color-1--2, 10%);
            &[aria-hidden="false"] {
                padding-left: 0;
                display: block;
                transform: inherit;
                height: auto;
                transition: inherit;
                background: $color-2--2;
            }
        }
        &.menu-main-1__item--active {
            padding: 1em 0 0;
            .menu-main-1__header {
                a {
                    padding-left: 35px;
                }
            }
        }
    }
}

// Level 3
.menu-main-1__listitems-3 {
    padding: .5em 0;
    border-right: 0;
    > li {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        > .menu-main-1__header {
            a {
                padding-top: .4em;
                padding-bottom: .4em;
                padding-left: 2.5em!important;
            }
            .menu-main-1__action {
                background: none;
                right: 20px;
                &:before {
                    color: $color-1--4;
                }
                &[aria-expanded="true"] {
                    &:before {
                        content: none;
                    }
                }
            }
        }
        > .menu-main-1__sublevel {
            display: none;
            position: static;
            width: 100%;
            transform: inherit;
            height: 0;
            transition: inherit;
            background: $color-2--2;
            &[aria-hidden="false"] {
                display: block;
                transform: inherit;
                height: auto;
                transition: inherit;
            }
        }
    }
}

// Level 4
.menu-main-1__listitems-4 {
    padding: .5em 0;
    > li {
        > .menu-main-1__header {
            a {
                padding-top: .3em;
                padding-bottom: .3em;
                padding-left: 1.7em;
            }
        }
    }
}
