// @name bloc publications

// @name Bloc publications in column and in go-so-far
.bloc-publications__picture {
    display: block;
    float: left;
    img {
        margin-right: 1em;
    }
}

.bloc-publications__wrapper {
    overflow: hidden;
    .list-document-1__listitems {
        .list-document-1__item {
            position: relative;
            a {
                color: $color-2--4;
            }
            &:before {
                background-color: $color-1--2;
                height: 22px;
                width: 22px;
                border-radius: 50%;
                color: $color-2--4;
                text-align: center;
                line-height: 1.8;
                vertical-align: middle;
            }
        }
    }
}

.bloc-publications__title {
    $bloc-publications__title__font-size: 2.4;
    font-size: #{$bloc-publications__title__font-size}em;
    font-family: $typo-3;
    font-weight: $light;
    margin: 0 0 em(.8, $bloc-publications__title__font-size);
    color: $color-black;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-1--2;
        }
        span {
            font-size: em(1.4, 2.2);
        }
    }
}

.column-bloc {
    .bloc-publications__listitems {
        padding-bottom: 5em;
    }
    .bloc-publications__title {
        $bloc-publications__title__font-size: 2.2;
        font-size: #{$bloc-publications__title__font-size}em;
        margin: 0 0 em(.8, $bloc-publications__title__font-size);
    }
}
