// @name site informations
.site-infos {
    .site-infos__img-wrap {
        float: left;
        margin-right: 2em;
        > img {
            margin-top: -3em;
            margin-left: -3em;
        }
    }
    .site-infos__wrapper {
        padding-top: 3em;
        overflow: hidden;
    }
    p, ul {
        font-size: 1.3em;
        font-style: normal;
        margin: em(.5, 1.3) 0 em(.8, 1.3);
        &:first-child {
            margin-top: 0;
        }
    }
    p {
        font-weight: $light;
        strong {
            text-transform: uppercase;
        }
    }
    ul {
        > li {
            margin: 0.2em 0;
        }
    }
    a {
        color: $color-white;
        font-size: em(1.6, 1.3);
        text-decoration: none;
        font-style: normal;
        &:hover, &:focus {
            text-decoration: underline;
        }
        &.telephone {
            @include default-icons-before('\e02b', $margin: 0 5px 0 0, $size: 0.8em, $color: $color-1--2, $va: middle, $font: 'icons-default');
            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }
    .button-2 {
        margin-top: 0.7em;
        background: $color-2--2;
        border-color: $color-2--2;
        padding: 1.45em 2.05em 1.45em 4.45em;
        @include default-icons-before('\e029', $margin: 0 10px 0 0, $size: 0.8em, $color: $color-1--2, $va: middle, $font: 'icons-default');
        &:hover, &:focus {
            text-decoration: none;
        }
        &:before {
            left: 2.2em;
            padding: 0.6em;
            border-radius: 50%;
            background-color: $color-2--3;
        }
    }
}
