// @name Document list
// @description List of document in publications list

.list-document__listitems {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -1%;
    > * {
        flex: 1 1 31.3333%;
        margin: 0 1% 3em;
    }
    .button-1, .button-2 {
        padding-left: 2.2em;
        padding-right: 1em;
        &:before {
            left: em(1.5, 1.4);
        }
    }
    .button-1 {
        padding-left: 2.7em;
    }
}

.list-document__item {
    &.list-document__item--postulate {
        text-align: right;
        align-self: flex-end;
    }
}

.list-document__title-file {
    font-size: 1.4em;
    font-weight: $bold;
    padding-left: em(4, 1.4);
    margin-bottom: em(1.5, 1.4);
    overflow: hidden;
    word-break: break-all;
    .list-document__file-size {
        display: block;
        font-weight: $light;
    }
    @include default-icons-absolute-before('\e005', 35px, $color-2--2, 2px, inherit, inherit, 0, 'icons-project');
    &.list-document__title-file--pdf {
        &:before {
            content: "\e003";
        }
    }
    &.list-document__title-file--doc {
        &:before {
            content: "\e001";
        }
    }
    &.list-document__title-file--jpg {
        &:before {
            content: "\e002";
        }
    }
    &.list-document__title-file--xls {
        &:before {
            content: "\e006";
        }
    }
    &.list-document__title-file--ppt {
        &:before {
            content: "\e004";
        }
    }
    &.list-document__title-file--zip {
        &:before {
            content: "\e007";
        }
    }
}

.list-document__download {
    &:before {
        content: "\e063";
        font-size: 10px;
    }
}

.list-document__read {
    &:before {
        content: "\e06e";
        font-size: 10px;
    }
}

// @name list document type 1
// @description Liste in bloc
.list-document-1__listitems {
    $list-document-1__listitems__font-size: 1.2;
    margin: 0 0 em(0.5, $list-document-1__listitems__font-size) 0;
    & + .list-document-1__listitems {
        padding: em(0.5, $list-document-1__listitems__font-size) 0 0;
    }
}

.list-document-1__item {
    $list-document-1__item__font-size: 1.2;
    font-size: #{$list-document-1__item__font-size}em;
    text-transform: uppercase;
    font-weight: $bold;
    margin: em(0.5, $list-document-1__item__font-size) 0;
    color: $color-black;
    position: relative;
    z-index: 99;
    @include default-icons-before('\e063', 0 5px 0 0, em(1.2, $list-document-1__item__font-size), $color-2--2, em(-.2, 1.2));
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-1--2;
            text-decoration: underline;
        }
    }
    &.list-document-1__item--read {
        &:before {
            content: "\e06e";
        }
    }
}

.list-document-1__file-size {
    font-weight: $light;
    white-space: nowrap;
}
