.home-agenda {
  font-family: $typo-2;
  .home-agenda__wrapper {
    padding: 35px 55px;
    .category {
      position: relative;
      padding-bottom: 20px;
      color: $color-2--2;
      &:after {
        position: absolute;
        bottom: 10px;
        left: -100px;
        content: '';
        width: 133px;
        height: 1px;
        background: #ed6d07;
      }
    }
    .home-agenda__title a {
      color: $color-black;
      font-family: $typo-1;
      font-weight: $light;
      font-size: 3.2em;
      line-height: 1;
    }
    &-date {
      margin-bottom: 2em;
      .date-1 {
        height: 62px;
        position: relative;
        color: #033162;
        time {
          width: 62px;
          height: 62px;
          margin: 0;
          position: absolute;
          padding-top: 1em;
          border-radius: 50%;
          &:nth-child(2) {
            left: -webkit-calc(50% - 57px);
            left: calc(50% - 57px);
            background: #feee7f;
          }
          &:nth-child(3) {
            right: -webkit-calc(50% - 57px);
            right: calc(50% - 57px);
            background: #fbc30a;
          }
        }
        .day {
          color: $color-2--3;
          font-size: 2.8em;
          font-weight: $bold;
        }
        .month {
          color: $color-2--3;
          font-size: 1.2em;
          font-weight: $light;
          text-transform: uppercase;
        }
      }
      .date-one {
        time {
          &:nth-child(2) {
            left: calc(50% - 31px);
            background: #fbc30a;
          }
        }
      }
    }
  }
  &__top {
    height: 325px;
    @extend .clear-fix;
    &-col {
      width: 50%;
      float: left;
      height: 100%;
      &:nth-child(1) {
        img {
          width: 100%;
          height: 100%;
        }
      }
      &:nth-child(2) {
        background: $color-4--2;
        position: relative;
        .home-agenda__wrapper {
          position: relative;
          z-index: 1;
          &-date {
            .date-1 {
              time {
                &:nth-child(2) {
                  left: 0;
                  background: #feee7f;
                }
                &:nth-child(3) {
                  right: inherit;
                  left: 52px;
                  background: #fbc30a;
                }
              }
            }
          }
        }
        &:before {
          height: 170px;
          width: 170px;
          content: '';
          position: absolute;
          left: -35px;
          top: calc(50% - 85px);
          background: $color-4--2;
          border-radius: 50%;
        }
        .button-1 {
          position: relative;
          z-index: 10;
          margin-top: 0.7em;
          padding: 1.45em 2.05em 1.45em 4.45em;
          @include default-icons-before('\e08a', $margin: 0 10px 0 0, $size: 0.8em, $color: $color-1--2, $va: middle, $font: 'icons-default');
          &:before {
            left: 2.2em;
            padding: 0.6em;
            border-radius: 50%;
          }
          &:hover:before {
            color: $color-2--3;
          }
        }
      }
    }
  }
  &__bottom {
    margin-top: 2px;
    display: flex;
    flex-wrap: wrap;
    &-col {
      margin: 0 0.1%;
      width: 24.8%;
      flex: 1;
      background: $color-3--1;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .home-agenda__wrapper {
        padding: 25px 50px 35px;
        .category {
          text-align: center;
          &:after {
            left: calc(50% - 16.5px);
            width: 33px;
          }
        }
        .home-agenda__title {
          text-align: center;
          a {
            font-size: 1.6em;
            &:hover, &:focus {
              color: $color-2--2;
            }
            &:focus {
              outline: 1px dashed $color-2--2;
            }
          }
        }
      }
    }
  }
  > .button-2 {
    width: 100%;
    padding-top: 1.17857em;
    padding-bottom: 1.17857em;
    margin-top: 0;
    text-align: center;
    &:before {
      content: none;
    }
  }
}