.home-info {
    &__wrapper {
        flex-direction: column;
    }

    &__image,
    &__content {
        width: 100%;
    }

    &__content {
        padding: 60px 70px 45px 45px;

        &::before {
            content: none;
        }
    }

    &__btn {
        margin-top: 30px;
    }
}
