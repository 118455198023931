// @name Go so far
// @description Sections content below the content

.go-so-far {
    background: $color-3--1;
    padding: 5.5em 0 7em 0;
    .list-content-2 {
        > li {
            > a {
                &:hover, &:focus {
                    color: $color-2--2;
                }
            }
        }
    }
}

.go-so-far__title {
}

.go-so-far__wrapper {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 -2%;
    > * {
        flex: 1;
        margin: 0 2%;
        &:last-child {
            flex: 2;
        }
    }
}

.go-so-far__item {
    &:first-child {
        position: relative;
        .hemicycle {
            width: 6em;
            height: 6em;
            position: absolute;
            top: -9.5em;
            left: calc(50% - 3em);
            background: #fff;
            border-radius: 50%;
        }
        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: -5.5em;
            width: 0.1em;
            height: 16em;
            @include background-opacity($color-1--3, $opacity: 0.2);
        }
    }
}
