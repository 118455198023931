.home-inform {
  .prevNextCaroussel3 {
    display: none;
  }
}

.home-info_wrapper {
  text-align: center;
  overflow: hidden;

  .home-info_item {
    width: 165px;
    margin: 7px 15px;
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: top;
    &:before {
      content: '';
      height: 1px;
      width: 200%;
      position: absolute;
      left: -240px;
      top: 50px;
      background: $color-1--2;
    }
    &:after {
      content: '';
      height: 1px;
      width: 200%;
      position: absolute;
      right: -240px;
      top: 50px;
      background: $color-1--2;
    }
    a {
      height: 100%;
      display: block;
      font-size: 1.4em;
      color: $color-2--3;
      font-weight: $bold;
      .home-info_icon {
        width: 100px;
        height: 100px;
        margin: 0 auto 28px;
        padding-top: 20px;
        position: relative;
        z-index: 1;
        background: #fcc22e;
        border: 10px solid $color-white;
        box-shadow: inset 3.9px 3.1px 0 rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        transition: background 0.3s ease;
        svg {
          fill: #4d4d4d;
          transition: fill 0.3s ease;
        }
        &:after {
          content: '';
          width: 9px;
          height: 9px;
          position: absolute;
          bottom: -24px;
          left: calc(50% - 4.5px);
          background: $color-1--3;
          border-radius: 50%;
        }
      }
      &:hover,
      &:focus {
        .home-info_icon {
          background: $color-2--2;
          box-shadow: inset 3.9px 3.1px 0 rgba(0, 0, 0, 0.25);
          svg {
            fill: $color-white;
          }
          &:after {
            background: $color-1--2;
          }
        }
      }
    }
  }
}