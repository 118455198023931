.home-consult {
  position: relative;
  z-index: 1;
  @extend .clear-fix;
  &__wrapper {
    height: 413px;
    overflow: hidden;
    &-left,
    &-right {
      float: left;
    }
    &-left {
      width: 65%;
      height: 100%;
      padding-left: 40px;
      background: $color-4--2;
      @extend .clear-fix;
      .home-consult__info {
        width: 45%;
        padding-top: 75px;
        float: left;
        > h3 {
          position: relative;
          margin-bottom: 35px;
          font-size: 2em;
          font-weight: $light;
          &:after {
            position: absolute;
            bottom: -18px;
            left: -40px;
            content: '';
            width: 133px;
            height: 1px;
            background: #ed6d07;
          }
        }
        &-subtitle {
          margin: 13px 0;
          position: relative;
          p {
            font-size: 1.6em;
            color: #004b90;
            font-weight: $light;
          }
          > div {
            content: '';
            width: 45px;
            height: 45px;
            position: absolute;
            left: -62.5px;
            top: calc(50% - 22.5px);
            border-radius: 50%;
            background: $color-2--1;
          }
          @include default-icons-before('\e017', $margin: 0, $size: 1.3em, $color: $color-white, $va: middle, $font: 'icons-default');
          &:before {
            position: absolute;
            left: -37px;
            z-index: 1;
            top: calc(50% - 7px);
          }
        }
      }
      .home-consult__img {
        width: 55%;
        height: 100%;
        padding-right: 5px;
        float: right;
        background: #fff url("../Images/bg/consult-for-img.png") no-repeat center right;
        img {
          height: 100%;
        }
      }
    }
    &-right {
      width: 35%;
      .form__field-wrapper {
        width: 360px;
        margin-left: auto;
        margin-right: 0;
        label {
          display: block;
          margin-left: auto;
          margin-right: 0;
          p {
            padding-left: 15px;
          }
          select {
            display: block;
            margin-left: auto;
            margin-right: 0;
            width: 345px;
            height: 45px;
            background-color: #f1f1f1;
            border: none;
            color: $color-2--3;
            font-size: em(1.3, 1.6);
            font-style: italic;
          }
        }
      }
      button.button-1 {
        margin-top: 0;
        margin-left: 75px;
        border-color: #004995;
        padding: 1.15em 0.65em 1.15em 3.05em;
        color: #fff;
        &:before {
          left: 2.2em;
          padding: 0.9em;
          border-radius: 50%;
          font-size: 0.5em;
        }
      }
    }
  }
}