$font-size__nav-flottante: 1;

.nav-flottante {
    position: fixed;
    z-index: 9999;
    top: 20%;
    right: 0;
    &__list {
        li {
            position: relative;
            width: 60px;
            height: 60px;
            &:before {
                content: '';
                width: 34px;
                height: 1px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -1px;
            }
            &:last-child {
                &:before {
                    display: none;
                }
            }
            &:hover {
                &:before {
                    display: none;
                }
            }
            &:nth-child(1) {
                .nav-flottante__item {
                    background: #2d5891;
                    > img {
                        position: absolute;
                        right: 14px;
                    }
                }
                .img-block {
                    background: $color-1--2;
                }
            }
            &:nth-child(2) {
                .nav-flottante__item {
                    background: #284f83;
                }
                .img-block {
                    background: $color-1--3;
                }
            }
            &:nth-child(3) {
                .nav-flottante__item {
                    background: #224573;
                }
                .img-block {
                    background: $color-2--6;
                }
            }
            &:nth-child(4) {
                .nav-flottante__item {
                    background: #1c385d;
                }
                .img-block {
                    background: #004b90;
                }
            }
            &:nth-child(5) {
                .nav-flottante__item {
                    background: #172e4d;
                }
                .img-block {
                    background: #014381;
                }
            }
        }
    }
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 260px;
        transform: translateX(0px);
        color: $color-white;
        transition: .5s;
        @extend .clear-fix;
        & > * {
            display: inline-block;
            vertical-align: middle;
        }
        p {
            font-size: em(1.2, $font-size__nav-flottante);
            font-family: $typo-2;
            font-weight: $bold;
            overflow: hidden;
            text-transform: uppercase;
            text-align: center;
        }
        &:hover,
        &:focus {
            transform: translateX(-200px);
            text-decoration: none;
        }
    }
    .img-block {
        width: 60px;
        height: 60px;
        line-height: 4;
        text-align: center;
    }
}

.menu-main-1__wrapper {
    .nav-flottante {
        display: none;
        position: static;
        margin-left: calc(50% - 130px);
    }
}
