.nav-flottante{
  display: none;
}

.menu-main-1__wrapper {
  .nav-flottante {
    display: block;
    margin-left: 0;
    &__list {
      li {
        width: 100%;
      }
    }
    &__item {
      width: 100%;
        &:hover,
        &:focus {
            transform: translateX(0);
        }
    }
  }
}
