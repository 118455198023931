.column-bloc.bloc-rapides {
  font-family: $typo-2;
  a {
    display: block;
    margin-bottom: 7px;
    color: $color-black;
    font-size: 1.5em;
    font-weight: $light;
    &:hover, &:focus {
      span {
        text-decoration: underline;
      }
    }
    &:focus {
      outline: 1px dashed $color-2--2;
    }
    > div {
      width: 43px;
      height: 43px;
      display: inline-block;
      position: relative;
      margin-right: 12px;
      background: $color-1--2;
      border-radius: 50%;
      vertical-align: middle;
      text-align: center;
      &:before {
        width: 10px;
        height: 10px;
        content: '';
        position: absolute;
        right: -5px;
        top: calc(50% - 5px);
        background: $color-1--3;
        border-radius: 50%;
      }
      img {
        height: 50%;
        width: auto;
        margin-top: 25%!important;
      }
    }
    > span {
      display: inline-block;
      vertical-align: middle;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}