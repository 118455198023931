.logo {
    position: absolute;
    bottom: -25px;
    left: 19px;
    font-size: 1.3em;
    z-index: 100;
    a {
        display: block;
    }
}
