// @name bloc news

// @name Bloc news in column
.column-bloc.bloc-news {
    img {
        max-width: 100%;
        height: auto;
        margin-bottom: 0;
    }
    picture {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: -2px;
            width: 100%;
            height: 18px;
            background: url("../Images/bg/bg-circle.png") repeat center;
        }
    }
    .bloc-news__wrapper {
        padding: 2em 3em 3em;
        text-align: center;
        background-color: $color-3--1;
        .category {
            position: relative;
            padding-bottom: 20px;
            font-size: 1.3em;
            color: $color-2--2;
            &:after {
                position: absolute;
                bottom: 10px;
                left: calc(50% - 16.5px);
                content: '';
                width: 33px;
                height: 1px;
                background: $color-1--3;
            }
        }
    }
    .bloc-news__title {
        $bloc-news__title__font-size: 1.8;
        font-family: $typo-3;
        font-weight: $light;
        font-size: #{$bloc-news__title__font-size}em;
        margin: 0 0 em(1.5, $bloc-news__title__font-size);
        color: $color-black;
        a {
            color: $color-black;
            &:hover, &:focus {
                color: $color-2--2;
            }
            &:focus {
                outline: 1px dashed $color-2--2;
            }
        }
    }
}
