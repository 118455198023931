// @name Go so far
// @description Sections content below the content

.go-so-far__wrapper {
    flex-wrap: wrap;
    > * {
        flex-basis: 100%;
    }
}

.go-so-far__item {
    &:first-child {
        padding-bottom: 25px;
        text-align: center;
        .hemicycle {
            left: 10px;
        }
        &:after {
            width: 16em;
            height: 0.1em;
            top: inherit;
            right: calc(50% - 8em);
            bottom: 0;
        }
    }
    &:last-child {
        margin-top: 30px;
        ul {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
}
