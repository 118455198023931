// @name Wrapper with wide 940px
.wrapper-main {
    width: 100%;
    max-width: 122rem;
    margin: 0 auto;
    padding: 0 1em;
    @extend .clear-fix;
}

// @name Background-in-wrapper
// @description Displayed in grey background on the sides.
.background-in-wrapper {
    position: relative;
    background: $color-3--1;
    &:before, &:after {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        width: 999em;
        height: 100%;
        background: $color-3--1;
    }
    &:before {
        left: -9990px;
    }
    &:after {
        right: -9990px;
    }
    // @description Displayed in dark background on the sides.
    &.background-in-wrapper--dark {
        background: $color-3--3;
        &:before, &:after {
            background: $color-3--3;
        }
    }
}

// @name Column bloc
// @description Generic bloc in .section-main__aside
$column-bloc__width: 35;
$column-bloc__padding-left: 8;
.column-bloc {
    width: #{$column-bloc__width}em;
    padding-left: #{$column-bloc__padding-left}em;
    margin-bottom: 9em;
    &:last-child {
        margin-bottom: 0;
    }
    .button-2 {
        width: 100%;
        padding-top: em(1.65, $button-2__font-size);
        padding-bottom: em(1.65, $button-2__font-size);
        margin-top: em(1, $button-2__font-size);
        color: $color-2--2;
        text-align: center;
        &:hover {
            color: $color-white;
        }
        &:before {
            content: none;
        }
    }
}

.column-bloc__wrapper {
    padding: 0;
}
