.home-actus {
  height: auto;
  &__col {
    width: 100%;
    float: none;
  }
  &__left {
    .home-actus__info {
      top: calc(50% - 110px)
    }
  }
}
