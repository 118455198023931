// @name Shame
// @description Because no project is perfect, you can add some code here if you don't know where to add it.

.section-main__aside > div > .column-bloc {
  padding-left: 8em;
}
.section-main__aside .list-infos__item,.section-main__aside .list-infos__item a {
  color: #000000;
}

.rte a[href^="http://"]:not([href*="fondationbonsauveur.com"]):after, .rte a[href^="https://"]:not([href*="fondationbonsauveur.com"]):after, .rte a[href^="//"]:not([href*="fondationbonsauveur.com"]):after {
  color: #fbc30a;
}

.fancybox-galerie.fancybox-wrap {
  min-width: 390px;
}