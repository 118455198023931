.home-kh {
  &-left,
  &-right {
    width: 100%;
    float: none;
  }
  &-left {
    img {
      width: 100%;
    }
    .kh-left-info {
      width: 300px;
      height: 300px;
      padding: 30px 20px 0 80px;
      bottom: -130px;
      left: -70px;
    }
  }
  &-right {
    margin-top: 2px;
    .kh-right-item {
      width: 49.8%;
      float: left;
      > img {
        width: 100%;
      }
      .kh-right-info {
        width: 100%;
        left: 0;
      }
      &:nth-child(1) {
        margin-right: 0.1%;
      }
      &:nth-child(2) {
        margin-left: 0.1%;
      }
    }

  }
}