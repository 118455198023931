.social-links {
    $this: &;

    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;

    @media (min-width: 1281px) and (max-width: 1440px) {
        flex-direction: column;
        justify-content: center;
    }

    &__item {
        margin: 0 15px;
    }

    &__link {
        align-items: center;
        color: $color-white;
        display: flex;
        justify-content: center;
        height: 3rem;
        position: relative;
        text-decoration: none;
        width: 3rem;


        &:hover,
        &:focus,
        &:active {
           #{$this}__link-icon::before {
               color: $color-1--2;
           }
        }

        &-icon {
            &:before {
                color: $color-2--2;
                font-family: "icons-default";
                font-size: 3rem;
                display: inline-block;
                line-height: normal;
                height: 100%;
                text-transform: inherit;
                vertical-align: middle;
                position: relative;
                width: 100%;

                @media (min-width: 1281px) and (max-width: 1440px) {
                    font-size: 2.2rem;
                }
            }

            .facebook & {
                &::before {
                    content: "\e098";
                }
            }
            .linkedin & {
                &::before {
                    content: "\e0a2";
                }
            }
            .youtube & {
                &::before {
                    content: "\e09d";
                }
            }
        }
    }

    .header__right & {
        margin-right: 15px;
    }

    .menu-main-1__wrapper & {
        display: none;
    }
}
