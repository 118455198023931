.go-to-top {
    display: block;
    //width: 100%;
    position: relative;
    right: 1%;
    bottom: 0;
    z-index: 100;
    p {
        margin-top: -5.9em;
        position: absolute;
        right: 0;
        text-align: right;
        .button-1 {
            padding: em(2.451, 1.4) em(2.951, 1.4);
            border-radius: 50%;
            background: $color-1--2;
            &:before {
                background: none;
                color: $color-1--3;
                font-size: 1em;
            }
            &:hover,
            &:focus {
                background: $color-1--3;
                &:before {
                    color: $color-1--2;
                }
            }
        }
    }
    a {
        &:before {
            content: "\e015";
        }
    }
}
