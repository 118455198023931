.home-inform {
  position: relative;
  .prevNextCaroussel3 {
    display: block;
    .prevCaroussel3 {
      position: absolute;
      top: calc(50% - 35px);
      left: 0;
    }
    .nextCaroussel3 {
      position: absolute;
      top: calc(50% - 35px);
      right: 0;
    }
    .prevCaroussel3,
    .nextCaroussel3 {
      button {
        img:nth-child(1) {
          display: block;
        }
        img:nth-child(2) {
          display: none;
        }
        &.disabled {
          img:nth-child(1) {
            display: none;
          }
          img:nth-child(2) {
            display: block;
          }
        }
      }
    }
  }
}
.home-info_wrapper {
  width: 96%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  .home-info_item {
    flex: 1;
    margin: 0;
    padding: 7px 15px;
    white-space: normal;
  }
}