.home-info {
    &__wrapper {
        display: flex;
    }

    &__image {
        @include default-icons-absolute-before('\e04b', $size: 60px, $color: lighten($color-black, 70%), $top: 50%, $left: 50%, $font: 'icons-default');
        @include responsive-ratio(955, 417, 'after');
        display: block;
        position: relative;
        width: calc(100% - 268px);

        &::before {
            transform: translate(-50%, -50%);
        }

        &::after {
            background: $color-4--2;
            height: 100%;
        }

        img {
            font-family: 'object-fit: cover;';
            height: 100%;
            left: 0;
            object-fit: cover;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    &__content {
        background: $color-2--2;
        color: $color-white;
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 1.4;
        padding: 77px 25px 48px 46px;
        position: relative;
        width: 268px;

        &::before {
            background: inherit;
            border-radius: 50%;
            content: '';
            height: 180px;
            left: -50px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 180px;
        }
    }

    &__teaser,
    &__btn {
        position: relative;
        z-index: 1;
    }

    &__btn {
        background: $color-white;
        color: $color-2--2;
        font-size: 1.3rem;
        margin-top: 55px;
        padding: 18px 23px 18px 43px;
        @include default-icons-before('\e08a', $margin: 0 10px 0 0, $size: 0.8em, $color: $color-1--2, $va: middle, $font: 'icons-default');

        &::before {
            font-size: 1rem;
            left: 15px;
            line-height: 1;
            padding: 5px 5px 5px 5.5px;
        }

        &:hover,
        &:focus {
            &::before {
                color: $color-2--3;
            }
        }
    }
}
